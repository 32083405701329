<template>
  <vs-card class="mt-6">
    <div slot="header">
      <div class="flex items-center">
        <h3 class="font-normal flex items-center">
          <p v-html="item.icon" class="mr-3"></p>
          {{ item.title }}
        </h3>
      </div>
    </div>
    <vs-row class="card-desc settings-grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3">
      <div
        v-for="(subitem, index) in item.sub"
        :key="subitem.title"
      >
        <div v-if="subitem.display"  :class="` settings-grid-container ${index >1 ? 'last-vline' : ''}`">
          <div v-if="subitem.display" class="flex items-center justify-between settings-grid-option">
            <div>
              <h5 class="mb-2" @click="gotoPage(subitem.route)">
                <p class="font-normal">{{ subitem.title }}</p>
              </h5>
              <p class="text-xs" @click="gotoPage(subitem.route)">
                {{ subitem.desc }}
              </p>
            </div>
            <div>
              <a @click="gotoPage(subitem.route)">
                <feather-icon
                  icon="ChevronRightIcon"
                  class="cursor-pointer link-card-right-icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    gotoPage(route) {
      this.$router.push({
        name: route,
      }).catch(()=>{});
    },
  },
};
</script>
